.center-pic {
    padding: 115px 0;
    background: #F0F8FA;
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__pic {
        width: 100%;
        max-width: 831px;
        margin-bottom: 80px;
        img {
            max-width: 700px;
            width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
    &__content {
        display: grid;
        grid-template-columns: 180px 1fr;
        grid-template-rows: repeat(2, auto);
        column-gap: 80px;
        width: 100%;
    }
    &__text {
        grid-row: 1 / 3;
        grid-column: 2;
        p {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h3 {
            font-weight: 300;
            font-size: 22px;
            line-height: 150%;
            margin-bottom: 10px;
        }
    }
    &__link {
        align-items: flex-end;
    }
    &_white {
        background: #FFFFFF;
        .center-pic {
            &__content {
                grid-template-columns: 205px 1fr;
                column-gap: 190px;
            }
            &__title {
                font-size: 40px;
                line-height: 120%;
            }
        }
    }
}

@media screen and (max-width: 1400px) { 
    .center-pic {
        &_white {
            .center-pic {
                &__content {
                    column-gap: 90px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) { 
    .center-pic {
        padding: 50px 0;
        &__content {
            grid-template-columns: 1fr;
        }
        &__title {
            margin-bottom: 25px;
        }
        &__text {
            grid-column: auto;
            grid-row: auto;
            margin-bottom: 20px;
        }
        &_white {
            .center-pic {
                &__pic {
                    margin-bottom: 30px;
                }
                &__content {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) { 
    .center-pic {
        padding: 40px 0;
    }
}

@media screen and (max-width: 660px) { 
    .center-pic {
        &__pic {
            margin-bottom: 40px;
        }
        &_white {
            overflow: hidden;
            .center-pic {
                &__pic {
                    margin-bottom: 9px;
                    img {
                        width: 580px;
                        max-width: none;
                    }
                }
                &__title {
                    font-weight: 300;
                    font-size: 22px;
                    line-height: 120%;
                    margin-bottom: 13px;
                }
                &__text {
                    p {
                        margin-bottom: 13px;
                    }
                    h3 {
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 128%;
                        color: #3B3C40;
                    }
                }
            }
        }
    }
}