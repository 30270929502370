$screen-mobile: 661px;
$screen-sm: 768px;
$screen-md: 920px;
$screen-lg: 1200px;

$for-guest-section-breakpoint: 1024px;

@mixin hide-scrollbar {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin section-title ($text-align: left, $mobile-text-align: left) {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.2;
  margin: 0 0 40px;
  text-align: $text-align;
  color: #3B3C40;

  @media screen and (max-width: $screen-mobile) {
    font-size: 24px;
    margin: 0 17px 32px;
    text-align: $mobile-text-align;
  }
}

.gift-card {
  .intro {
    &__wrap {
      display: flex;
      margin: auto;

      @media screen and (max-width: $screen-sm) {
        flex-direction: column;
      }
    }

    &__head-text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      white-space: nowrap;
    }

    &__head-text {
      font-weight: 500;
      font-size: 38px;
      line-height: 1.7;

      &_accent {
        color: #BABE8A;
      }

      @media screen and (max-width: $screen-lg) {
        font-size: 24px;
        line-height: 1.4;
        margin: 17px 0 0;
      }

      @media screen and (max-width: $screen-mobile) {
        font-size: 16px;
      }
    }

    &__promo-img {
      @media screen and (max-width: $screen-sm) {
        margin-top: 14px;
      }
    }
  }

  &__action-btn {
    display: block;
    color: #fff;
    width: max-content;
    margin-top: 56px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    background: linear-gradient(44deg, #41888C 0%, #52C1C8 100%);
    padding: 23px 48px;
    text-transform: none;
    white-space: nowrap;
    text-align: center;

    @media screen and (max-width: $screen-md) {
      font-size: 16px;
      line-height: 1.25;
      margin-top: 24px;
    }

    @media screen and (max-width: $screen-mobile) {
      width: 100%;
      margin: 10px auto 13px;
      padding: 19px 8px;
    }

    &_outlined {
      color: #3B3C40;
      border: 1px solid #74BBBF;
      background: #fff;
    }
  }

  .intro_card {
    height: 100vh;
    max-height: 854px;
    min-height: 0;

    @media screen and (max-width: $screen-lg) {
      height: max-content;
    }

    @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
      height: 384px;
      padding-top: 32px;
    }

    .gift-card__action-btn {
      @media screen and (max-width: $screen-mobile) {
        display: none;
      }

      &_mobile {
        display: none;
  
        @media screen and (max-width: $screen-mobile) {
          display: block;
        }
      }
    }
  }

  .intro__logo .logo-png {
    @media screen and (max-width: $screen-mobile) {
      display: block;
      height: 28px;
    }
  }

}

.header_gift-card {
  .header__logo {
    display: none;
  }
}

.earn {
  background: #F0F8FA;
  padding: 100px 21px;
  color: #2A2A2D;

  @media screen and (max-width: $screen-mobile) {
    padding: 40px 21px 32px;
  }

  &__container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 32px;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: $screen-mobile) {
      font-size: 24px;
      margin: 0 auto 16px;
      max-width: 270px;
    }
  }

  &__cards-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 42px;

    @media screen and (max-width: $screen-sm) {
      grid-template-columns: 1fr;
      gap: 16px;
      justify-items: center;
      max-width: 272px;
      margin: 0 auto;
    }
  }

  &__card {
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 20px 20px 170px;
    color: #3B3C40;
    position: relative;

    @media screen and (max-width: $screen-mobile) {
      width: 100%;
      padding: 15px 15px 150px;
    }
  }

  &__card-title {
    margin-bottom: 4px;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;

    @media screen and (max-width: $screen-mobile) {
      font-size: 25px;
      font-weight: 700;
      line-height: 1.2;
    }
  }

  &__card-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
  }

  &__card-img {
    width: 70%;
    max-width: 230px;
    position: absolute;
    right: 0;
    bottom: 0;

    @media screen and (max-width: $screen-mobile) {
      font-size: 25px;
      font-weight: 700;
      line-height: 1.2;
    }

    @media screen and (max-width: $screen-sm) {
      max-width: 170px;
    }
  }
}

.trc-benefits {
  padding: 100px 16px;
  background: #F0F8FA;
  overflow-x: hidden;

  @media screen and (max-width: $screen-mobile) {
    padding: 40px 0 50px;
  }

  &__container {
    padding: 0;
    margin: 0 auto;
    max-width: 1170px;
  }

  &__title {
    @include section-title();
  }

  &__list {
    padding-bottom: 40px;
    display: flex;
    height: max-content;
    @include hide-scrollbar;

    @media screen and (max-width: $screen-lg) {
      overflow: auto;
    }

    @media screen and (max-width: $screen-mobile) {
      padding: 0 20px 20px;
    }
  }

  &__item {
    padding: 28px 25px 25px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    width: 267px;
    min-width: 267px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    @media screen and (max-width: $screen-mobile) {
      padding: 16px;
      width: 270px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &-icon {
      width: 40px;
      margin-bottom: 33px;

      @media screen and (max-width: $screen-mobile) {
        width: 32px;
        margin-bottom: 8px;
      }
    }

    &-title {
      height: 56px;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      padding-bottom: 10px;
      border-bottom: 1px solid #74BBBF;
      margin-bottom: 20px;
      color: #2A2A2D;

      @media screen and (max-width: $screen-mobile) {
        height: max-content;
        margin-bottom: 8px;
        line-height: 1.2;
        border-width: 0;
      }
    }

    &-text {
      color: #3B3C40;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;

      div:not(:last-child) {
        margin-bottom: 20px;
      }

      &_accent {
        font-weight: 700;
        color: #74BBBF;
      }

      @media screen and (max-width: $screen-mobile) {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;
      }
    }
  }

  &__btn-wrap {
    padding: 0 17px;
  }

  .gift-card__action-btn {
    margin: auto;
    display: block;

    @media screen and (max-width: $screen-mobile) {
      padding: 19px 30px;
    }
  }
}

.services {
  padding: 100px 0 100px;

  @media screen and (max-width: $screen-lg) {
    padding: 44px 0 0;
  }

  &__container {
    padding: 0;
    margin: 0 auto;
    max-width: 1170px;
  }

  &__title {
    @include section-title(left, center);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    height: max-content;

    @media screen and (max-width: $screen-lg) {
      flex-wrap: nowrap;
      padding: 0 48px 8px;
      overflow: auto;
      @include hide-scrollbar;
    }
  }

  &__item {
    width: 364px;
    margin: 0 30px 30px 0;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 60px 0;
    text-align: center;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media screen and (max-width: $screen-lg) {
      width: 183px;
      min-width: 183px;
      margin: 0 16px 16px 0;
      padding: 32px 0;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &__item-icon-wrap {
    border-radius: 50%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.10);
    margin: 0 auto;
    margin-bottom: 42px;
    width: 178px;
    height: 178px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $screen-lg) {
      width: 59px;
      height: 59px;
    }
  }

  &__item-icon {
    width: 64px;
    height: 64px;

    @media screen and (max-width: $screen-lg) {
      width: 33px;
      height: 33px;
    }
  }

  &__item-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 31px;

    @media screen and (max-width: $screen-lg) {
      font-size: 17px;
      font-weight: 400;
      line-height: 1.28;
    }
  }
}

.clients {
  padding: 100px 0 100px;

  @media screen and (max-width: $screen-mobile) {
    padding: 44px 0 27px;
  }

  &__container {
    padding: 0;
    margin: 0 auto;
    max-width: 1170px;
  }

  &__title {
    @include section-title(left, center);

    @media screen and (max-width: $screen-mobile) {
      margin-bottom: 25px;
    }
  }

  &__list {
    padding-bottom: 40px;
    display: flex;
    overflow: auto;
    height: max-content;
    @include hide-scrollbar;

    @media screen and (max-width: $screen-mobile) {
      padding: 0 48px;
    }
  }

  &__item {
    text-align: center;
  }

  &__item-icon {
    width: 142px;
    margin: 0 auto;
  }

  &__item-title {
    color: #6C6E75;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 20px;
    width: 195px;
  }
}

.gift-card {

  @media screen and (min-width: 1025px) {
    .card-types__sticky {
      flex: unset;
    }

    .card-types__sticky,
    .for-guest__image-wrap {
      width: 600px;
      min-width: 600px;
    }
  }

  .card-types__wrapper::before {
    height: calc(100% - 165px);

    @media screen and (max-width: $screen-lg) {
      height: calc(100% - 145px);
    }
  }
}

.for-guest {
  background: #F0F8FA;
  display: flex;
  color: #3B3C40;

  @media screen and (max-width: $for-guest-section-breakpoint) {
    flex-direction: column;
  }

  &__title {
    @include section-title();
    margin-bottom: 57px;
    max-width: 371px;

    @media screen and (max-width: $for-guest-section-breakpoint) {
      margin: 0 0 32px;
    }
  }

  &__content {
    max-width: 695px;
    padding: 69px 17px 75px 229px;

    @media screen and (max-width: 1300px) {
      padding-left: 17px;
    }

    @media screen and (max-width: $for-guest-section-breakpoint) {
      padding: 40px 65px 27px 17px;
    }
  }

  &__list {
    margin-left: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    max-width: 466px;

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    @media screen and (max-width: $for-guest-section-breakpoint) {
      flex-direction: column;
      align-items: start;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &__icon {
    margin-right: 26px;

    @media screen and (max-width: $for-guest-section-breakpoint) {
      margin-bottom: 8px;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;

    @media screen and (max-width: $for-guest-section-breakpoint) {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.4;
    }
  }

  &__image-wrap {
    overflow: hidden;
    position: relative;

    @media screen and (max-width: $for-guest-section-breakpoint) {
      overflow: hidden;
      order: 1;
      width: 100%;
      height: 415px;
      position: relative;
    }
  }
 
  &__image {
    position: absolute;
    height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;

    @media screen and (max-width: $for-guest-section-breakpoint) {
      width: 100%;
      height: auto;
    }
  }
}

.stats {
  padding: 100px 0;
  color: #3B3C40;

  @media screen and (max-width: $screen-md) {
    padding: 40px 17px 50px;
  }

  &__container {
    width: 100%;
    max-width: 716px;
    margin: auto;
  }

  &__title {
    @include section-title(center, center);
    margin-bottom: 40px;

    @media screen and (max-width: $screen-md) {
      margin-bottom: 24px;
    }
  }

  &__table {
    margin-bottom: 56px;

    @media screen and (max-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D5D5D8;

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
      align-items: start;
      padding-bottom: 8px;
      margin-bottom: 15px;
    }
  }

  &__cell {
    height: 68px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: #3B3C40;

    @media screen and (max-width: $screen-md) {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.2;
    }

    &:first-child {
      white-space: nowrap;
      min-width: 272px;
      max-width: 272px;
      color: #6C6E75;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;

      @media screen and (max-width: $screen-md) {
        color: #3B3C40;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
      }
    }

    @media screen and (max-width: $screen-md) {
      height: auto;
    }
  }

  &__row_turnover &__cell {
    &:first-child {
      color: #3B3C40;
      font-size: 32px;
      font-weight: 600;
      line-height: 1.2;
    }
  }

  &__row_profit {
    border-color: #74BBBF;

    .stats__cell {
      color: #74BBBF;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;

      @media screen and (max-width: $screen-md) {
        color: #3B3C40;
        font-size: 13px;
        font-weight: 300;
        line-height: 1.2;
      }

      &:first-child {
        color: #74BBBF;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2;
      }
    }
  }

  &__row_turnover,
  &__row_profit {
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (max-width: $screen-md) {
      padding-left: 0;
      padding-right: 0;

      .stats__cell:first-child {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }

  &__annotation {
    padding: 8px 8px 0 280px;
    color: #6C6E75;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.4;

    @media screen and (max-width: $screen-md) {
      padding: 0;
      font-size: 10px;
      font-weight: 300;
      line-height: 1.4;
    }
  }

  &__btns-wrap {
    display: flex;
    justify-content: center;
    padding-top: 24px;

    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
    }

    & > * {
      margin-top: 0;

      &:not(:last-child) {
        margin-right: 20px;

        @media screen and (max-width: $screen-mobile) {
          margin: 0 0 12px
        }
      }
    }
  }
}
